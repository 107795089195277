import React from "react";
import Img34 from "../../../assets/images/34.svg";
import Img35 from "../../../assets/images/35.png";
import Img36 from "../../../assets/images/36.png";
import Img37 from "../../../assets/images/37.png";

export default function ITServices() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-10 pb-30 md-pt-10 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 md-mb-50">
              <div className="images-part">
                <img src={Img34} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  Engagement, Conversion and Retain Clients/Customers
                </h2>
                <p className="mt-10">
                  The financial services industry is rapidly evolving every day.
                  In order to keep pace, IT firms need to consistently address
                  the following industry challenges through digital network,
                  which are important points to short/long-term success:
                </p>
                <p className="mt-10">
                  Rising Customers are demanding latest, personalized
                  experiences with their financial critics. However, customers
                  are expecting to interact with all the firms on small devices
                  and, make key transactions digitally and to be served
                  personalized content based on their financial needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Always Stay Ahead of a Powerful Business and Encourage them.{" "}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-icon">
                  <img src={Img35} alt="Services" />
                </div>
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Growing in industry <br />
                      as competitor
                    </a>
                  </h2>
                  {/* <p className="services-txt"> he MapReduce task is mainly divided into 2 phases i.e. Map phase and Reduce phase. Map: As the name suggests its main use is to map the input data in key-value pairs.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-icon">
                  <img src={Img36} alt="Services" />
                </div>
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Shareholders Investor Expectations</a>
                  </h2>
                  {/* <p className="services-txt"> The frequency of a particular data value is the number of times the data value occurs.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-icon">
                  <img src={Img37} alt="Services" />
                </div>
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Ensuring Compliance and Performance</a>
                  </h2>
                  {/* <p className="services-txt"> On the processing side, there are a few choices: Samza, Storm and Spark Streaming are the most popular stream processing frameworks.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}
