import React from "react";
import Img39 from "../../../assets/images/39.png";

export default function ProjectAccounting() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-0 pb-30 md-pt-00 md-pb-30"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pl-46 md-pl-15 mt-100">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  Logistics has many multiple definitions, for example:
                </h2>

                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      The detailed coordination of a complex operation involving
                      many people, facilities, or supplies.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      The organization of moving, housing, and supplying troops
                      and equipment.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      The commercial activity of transporting goods to
                      customers.
                    </span>
                  </li>
                </ul>

                <p className="mt-10">
                  <i>
                    The definition suitable for SKSoft Systems is “The
                    commercial activity of transporting goods to customers”
                  </i>
                </p>

                <h2 className="title pb-30 pt-30">
                  {" "}
                  <b>There are multiple types of Logistics:</b>
                </h2>

                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      <b>
                        Sales Logistics: Delivery from Warehouse to Wholesalers,
                        Retailers, Customers
                      </b>
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Procurement Logistics: Procuring raw materials and parts
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Production Logistics: Materials Management, Product
                      Management, Shipping
                    </span>
                  </li>
                </ul>
              </div>

              <div className="sec-title3 mt-50">
                <h2 className="title pb-20">
                  The definitions suitable for Suchi ERP is “Sales Logistics:
                  Delivery to Customers”
                </h2>

                <p className="mt-0">
                  Transportation is a major aspect of Logistics. As without
                  Transport, the goods would not be delivered to the customers.
                  The modes of Transport are Road, Rail, Air and Sea.
                  <br />
                  <b>
                    <i>
                      The logistics module from SKSoft Systems focuses on Road
                      transport mainly using Trucks to deliver goods to
                      customers. This falls under the “outbound” Logistics as
                      per definition.
                    </i>
                  </b>
                </p>
              </div>

              <div className="sec-title3 mt-50">
                <h2 className="title pb-20">Therefore, to summarize:</h2>

                <p className="mt-0">
                  SKSoft Systems has developed an outbound logistics module that
                  helps companies dealing with transport of goods from
                  warehouses to customers, using Road transportation. This is a
                  cloud-based Sales Logistics solution integrated with
                  internet-based gateway for payments.
                </p>
              </div>
            </div>
            <div className="col-lg-5 md-mb-30 mt-30">
              <div className="images-part">
                <img src={Img39} alt="Images" className="w-100 " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
