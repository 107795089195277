import React from "react";
import Img32 from "../../../assets/images/32.png";
import Img34 from "../../../assets/images/34.svg";
import Img35 from "../../../assets/images/35.png";
import Img36 from "../../../assets/images/36.png";
import Img37 from "../../../assets/images/37.png";

export default function BusinessSolutions() {
  const Img33 = require("../../../assets/images/33.svg");

  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-0">
            <div className="col-lg-6 md-mb-50 mt-50">
              <div className="images-part">
                <img src={Img32} alt="Images" className="w-100 " />
              </div>
            </div>
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  Strategic Solutions for Sustainable Succes
                </h2>

                <p className="mt-10">
                  SKSoft Systems goes beyond providing services; we offer
                  strategic business solutions that lay the foundation for your
                  sustained success. Our approach is rooted in understanding
                  your business intricacies, challenges, and aspirations.
                  Explore how our solutions can be the catalyst for your
                  business growth.
                </p>
                <p className="mt-10">
                  Our portfolio of business solutions is crafted to address the
                  core challenges faced by modern enterprises. Whether you're
                  looking to optimize operations, enhance customer engagement,
                  or drive digital transformation, SKSoft Systems has the
                  expertise to guide you. We offer end-to-end solutions that
                  encompass everything from consultation to implementation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Always Stay Ahead of a Powerful Business and Encourage them.{" "}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-icon">
                  <img src={Img35} alt="Services" />
                </div>
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Growing in industry <br />
                      as competitor
                    </a>
                  </h2>
                  {/* <p className="services-txt"> he MapReduce task is mainly divided into 2 phases i.e. Map phase and Reduce phase. Map: As the name suggests its main use is to map the input data in key-value pairs.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-icon">
                  <img src={Img36} alt="Services" />
                </div>
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Shareholders Investor Expectations</a>
                  </h2>
                  {/* <p className="services-txt"> The frequency of a particular data value is the number of times the data value occurs.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-icon">
                  <img src={Img37} alt="Services" />
                </div>
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Ensuring Compliance and Performance</a>
                  </h2>
                  {/* <p className="services-txt"> On the processing side, there are a few choices: Samza, Storm and Spark Streaming are the most popular stream processing frameworks.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-30">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  Why Opt for SKSoftSystems' Business Solutions:
                </h2>
                <p className="mt-10">
                  <b>Holistic Approach:</b> Our solutions cover the entire
                  spectrum of business needs, ensuring a comprehensive and
                  integrated strategy.
                </p>

                <p className="mt-10">
                  <b>Strategic Consulting: </b> Benefit from our strategic
                  consulting services that provide insights and roadmaps for
                  sustainable growth.
                </p>
                <p className="mt-10">
                  <b>Technology Integration:</b> We seamlessly integrate
                  cutting-edge technologies into our solutions, keeping you at
                  the forefront of innovation.
                </p>
                <p className="mt-10">
                  <b>Measurable Results:</b> Our solutions are designed for
                  tangible, measurable impact on your business performance.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="images-part">
                <img src={Img33} alt="Images" className="w-100 " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
