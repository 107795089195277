import React, { FunctionComponent } from "react";

import { Link } from "react-router-dom";

const Footer: FunctionComponent = () => {
  return (
    <>
      {/* Footer Start */}
      <footer id="rs-footer" className="rs-footer style1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <div className="footer-logo mb-40">
                  <a href="/">
                    <img src="assets/images/brand-logo.svg" alt="logo" />
                  </a>
                </div>
                <div className="textwidget white-color pb-40">
                  <p>
                    SKSoft Systems combines the best of technical teams,
                    futuristic innovations, and streamlined processes to offer
                    you comprehensive business solutions.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10 pl-55 md-pl-15">
                <h3 className="footer-title">Services</h3>
                <ul className="site-map">
                  <li>
                    <Link to="">
                      <h4>
                        <b>AI and Data</b>
                      </h4>
                    </Link>
                  </li>

                  <li>
                    <Link to="data-strategy">Data Strategy</Link>
                  </li>
                  <li>
                    <Link to="data-science">Data Science</Link>
                  </li>
                  <li>
                    <Link to="data-engineering">Data Engineering</Link>
                  </li>

                  {/* <li>
                    <Link to="/Overview">Overview</Link>
                  </li>

                  <li>
                    <Link to="/Payroll">Payroll</Link>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <h3 className="footer-title">Contact Info</h3>
                <ul className="address-widget">
                  <li>
                    <div className="desc">
                      2100 Manchester, Bldg A, SUITE: 615, Wheaton, IL. 60187.
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call" />
                    <div className="desc">
                      <a href="tel:(+880)15569569365">(945) 257-3724 </a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email" />
                    <div className="desc">
                      <a href="mailto:info@sksoftsystems.com">
                        info@sksoftsystems.com
                      </a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-clock-1" />
                    <div className="desc">Office Hours: 9AM - 5PM</div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <h3 className="footer-title">Stay with us</h3>
                <p className="widget-desc white-color">
                  Embark on a journey of innovation, collaboration, and success
                  with SKSoft Systems. Let's shape the future of your business
                  together.
                </p>
                {/* <p>
                                    <input type="email" name="EMAIL" placeholder="Your email address" required />
                                    <input type="submit" defaultValue="Subscribe Now" />
                                </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="copyright text-lg-start text-center ">
                  <p>
                    © 2023 SKSoft Systems - Consulting Company Inc. developed by{" "}
                    <a href="https://newdevex.com">NEWDEVEX</a> . All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
      {/* Footer End */}

      {/* start scrollUp  */}
      <div id="scrollUp" className="orange-color">
        <i className="fa fa-angle-up" />
      </div>
      {/* End scrollUp  */}
    </>
  );
};

export default Footer;
