import React from "react";

export default function Videos() {
  return (
    <div>
      {/* Project Section Start */}
      <div className="rs-project style2 pt-50 pb-30 md-pt-50 md-pb-30">
        <div className="container">
          {/* <div className="gridFilter mb-50 md-mb-30 text-center">
              <button className="active" data-filter="*">All</button>
              <button data-filter=".filter1">Team Discussions</button>
              <button data-filter=".filter2">ERP</button>             
            </div>                     */}
          <div className="row grid">
            {/* filter 1 Start */}

            <div className="col-lg-6 col-md-6 mb-30 grid-item filter1">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/bAyrObl7TYE?si=DpPHPZMpzR8LveEc"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 1 End */}

            {/* filter 2 Start */}
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter2">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/qz0aGYrrlhU?si=UO3HPHGCDuETf-Aa"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 2 End */}

            {/* filter 3 Start */}
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter3">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/nu_pCVPKzTk?si=0xEC4DY4IRBjXAHa"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 3 End */}

            {/* filter 3 Start */}
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter4">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/kqtD5dpn9C8?si=ZFSC4NOC69tT5cWP"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 3 End */}

            <div className="col-lg-6 col-md-6 mb-30 grid-item filter1">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/cYlzIeXAAtI?si=S_KEeL_z2sSjIYvY"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Project Section End */}
    </div>
  );
}
