import React, { useRef } from "react";
// import emailjs from "emailjs-com";
// import Swal from "sweetalert2";

const Contactus = () => {
  const form = useRef();

  //   const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs
  //       .sendForm(
  //         "service_pyfyy4g",
  //         "template_fz2x8qa",
  //         form.current,
  //         "user_FIgygPWj3vQuFBn77mIao"
  //       )
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //           Swal.fire({
  //             title: "Thank you for registering!",
  //             text: "We will get back soon.",
  //             icon: "success",
  //             confirmButtonText: "OK",
  //           });
  //         },
  //         (error) => {
  //           console.log(error.text);
  //           Swal.fire({
  //             title: "Error!",
  //             text: error?.response?.data?.error?.message
  //               ? error?.response?.data?.error?.message
  //               : "Something went wrong!",
  //             icon: "error",
  //             confirmButtonText: "OK",
  //           });
  //         }
  //       );
  //     e.target.reset();
  //   };

  return (
    <>
      <div>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img8">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                Have questions - contact us
                <span className="watermark">Contact</span>
              </h1>
              <span className="sub-text">We help you.</span>
            </div>
          </div>
        </div>
        {/* Breadcrumbs End */}

        {/* Contact Section Start */}
        <div className="rs-contact contact-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
          <div className="container">
            <div className="row margin-0">
              <div className="col-lg-6 padding-0">
                <div className="contact-address">
                  <div className="sec-title mb-12">
                    <h2 className="title pb-20">Company Details</h2>
                  </div>
                  {/* <div className="address-item mb-25">
                    <div className="address-text">
                      <b>OWNER & Signing Authority:</b>
                      <br />
                      <p>Krishna Patel</p>
                    </div>
                  </div> */}
                  <div className="address-item mb-25">
                    <div className="address-text">
                      <b>Type of Business: </b>
                      <br />
                      <p> IT Services</p>
                    </div>
                  </div>
                  {/* <div className="address-item mb-25">
                    <div className="address-text">
                      <b>EIN: </b>
                      <br />
                      <p> 934112294</p>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 padding-0">
                <div className="contact-address">
                  <div className="sec-title mb-46">
                    <h2 className="title pb-20">Get in touch</h2>
                    <p className="margin-0">
                      Objectively innovate your empowered manufactured products
                      whereas parallel platforms for your ideas.
                    </p>
                  </div>

                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/1.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      2100 Manchester, Bldg A, SUITE: 615, Wheaton, IL. 60187.
                    </div>
                  </div>

                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/5.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      <a href="tel:(+088)589-8745">(945) 257-3724</a>
                      <br />
                    </div>
                  </div>
                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/6.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      <a href="mailto:info@sksoftsystems.com">
                        info@sksoftsystems.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Section End */}

        {/* Contact Section Start*/}
        <div className="rs-contact main-home office-modify1 pt-30 pb-30 md-pt-30 md-pb-30">
          <div className="container">
            <div className="row margin-0">
              <div className="col-lg-6 padding-0 office-buliding" />
              <div className="col-lg-6 padding-0">
                <div className="contact-section contact-style2">
                  <div className="sec-title mb-60">
                    <h2 className="title">Contact us</h2>
                  </div>
                  <div className="contact-wrap">
                    <div id="form-messages" />
                    <form id="contact-form" method="post" action="mailer.php">
                      <fieldset>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="email"
                              name="email"
                              placeholder="E-Mail"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Phone Number"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                            <input
                              className="from-control"
                              type="text"
                              id="Website"
                              name="subject"
                              placeholder="Your Website"
                              required
                            />
                          </div>
                          <div className="col-lg-12 mb-35">
                            <textarea
                              className="from-control"
                              id="message"
                              name="message"
                              placeholder="Your message Here"
                              required
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="btn-part">
                          <div className="form-group mb-0">
                            <input
                              className="readon submit"
                              type="submit"
                              defaultValue="Submit Now"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Section End */}
      </div>
    </>
  );
};

export default Contactus;
