import React from "react";
import { Link } from "react-router-dom";

//components
import Img1 from "../../assets/images/1.svg";
import Img2 from "../../assets/images/2.svg";
import Img3 from "../../assets/images/3.png";
import Img43 from "../../assets/images/43.svg";
import Img44 from "../../assets/images/44.svg";
import P1 from "../../assets/images/p1.svg";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      campaigns: [],
      isLoading: false,
    };
  }

  render() {
    return (
      <>
        <div>
          {/* Slider Start */}
          <div id="rs-slider" className="rs-slider slider3 py-5 px-5">
            <div className="bend niceties">
              <div id="nivoSlider" className="slides hidden-xs">
                <img src={Img1} alt="" title="#slide-1" />
                {/* <img src={Img2} alt="" title="#slide-2" /> */}
              </div>
              <div id="nivoSlider" className="slides visible-xs">
                <img src="assets/images/m1.png" alt="" title="#slide-1" />
                <img
                  src="assets/images/2.png hidden-xs"
                  alt=""
                  title="#slide-2"
                />
              </div>
              {/* Slide 1 */}
              <div id="slide-1" className="slider-direction">
                <div className="content-part">
                  <div className="container">
                    {/* <div className="slider-des">
                                            <div className="sl-subtitle">A project that functions </div>
                                            <h1 className="sl-title">SKSoft Systems is equipped with <br/> the latest technology</h1>
                                        </div>
                                        <div className="desc">We fuse innovation and business plans to create <br />excellent business solutions!</div>
                                        <div className="slider-bottom ">
                                            <a href="/" className="readon consultant slider">Discover More</a>
                                        </div> */}
                  </div>
                </div>
              </div>
              {/* Slide 2 */}
              <div id="slide-2" className="slider-direction">
                <div className="content-part">
                  <div className="container"></div>
                </div>
              </div>
            </div>
          </div>
          {/* Slider End */}

          <div class="rs-services style7 gray-bg3 pt-30 pb-30">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6 md-mb-30">
                  <div class="services-item">
                    <div class="services-icon">
                      <img
                        src="assets/images/services/style7/icons/1.png"
                        alt="Images"
                      />
                    </div>
                    <div class="services-content">
                      <h2 class="title"> Outstanding Capabilities</h2>
                      <p class="services-txt">
                        {" "}
                        Multi-device and remote accessibility
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 md-mb-30">
                  <div class="services-item">
                    <div class="services-icon">
                      <img
                        src="assets/images/services/style7/icons/2.png"
                        alt="Images"
                      />
                    </div>
                    <div class="services-content">
                      <h2 class="title">Complete Solutions</h2>
                      <p class="services-txt">
                        {" "}
                        Packaged as a full-featured ERP solution.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="services-item">
                    <div class="services-icon">
                      <img
                        src="assets/images/services/style7/icons/3.png"
                        alt="Images"
                      />
                    </div>
                    <div class="services-content">
                      <h2 class="title"> Favourably Acclaimed by Clients</h2>
                      <p class="services-txt"> 24/7 clients services</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="rs-faq faq-home-style5 pt-70 pb-30 md-pt-70 md-pb-30">
            <div class="container">
              <div class="sec-title3 text-center mb-60">
                {/* <div class="icon-part mb-25">
                                    <img src="assets/images/brand-logo.svg" alt="" style={{width: '200px'}}/>
                                </div> */}
                <h2 class="title pb-20">
                  Choose <b>SKSoft Systems</b> for a reliable partner dedicated
                  to your success.
                </h2>
                <p class="dese margin-0">
                  With SKSoft Systems, you can gather, manage, and organise
                  information for your business <br /> with the most
                  functionality, technology, and intuition available.
                </p>
              </div>
              <div class="row y-middle">
                <div class="col-lg-6 padding-0 md-mb-50">
                  <div class="rs-videos faq">
                    <img src={P1} alt="" />
                    <div class="animate-border">
                      <a
                        class="popup-border"
                        href="https://www.youtube.com/watch?v=JK2MdJAWEGc&list=PLlgLmuG_KgbasW0lpInSAIxYd2vqAEPit&ab_channel=GreatLearning"
                      >
                        <i class="fa fa-play"></i>
                        {/* <video width="320" height="240" controls>
                                                    <source src={Img7} type="video/mp4"/>
                                                </video> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pl-55 md-pl-15">
                  <div class="content-part">
                    <div class="faq-content">
                      <div id="accordion" class="accordion">
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                            >
                              What services does your software company and
                              consultancy offer?
                            </a>
                          </div>
                          <div
                            id="collapseFour"
                            class="collapse show"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <p>
                                  We specialize in a range of services,
                                  including software development, consultancy,
                                  and tailored solutions. Our expertise spans
                                  various domains, from cutting-edge
                                  technologies like Big Data and Cloud Computing
                                  to custom software development and strategic
                                  consulting
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                            >
                              How does your company approach the software
                              development process?
                            </a>
                          </div>
                          <div
                            id="collapseFive"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <p>
                                  Our software development process is
                                  characterized by a collaborative and iterative
                                  approach. We work closely with our clients to
                                  understand their requirements, incorporate
                                  feedback throughout the development lifecycle,
                                  and ensure the final product aligns with their
                                  vision.
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                            >
                              What industries does your consultancy cater to?
                            </a>
                          </div>
                          <div
                            id="collapseSix"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <p>
                                  We have experience working across diverse
                                  industries, including but not limited to
                                  finance, healthcare, e-commerce, and
                                  telecommunications. Our team's versatility
                                  allows us to adapt our solutions to the unique
                                  challenges and requirements of each industry,
                                  providing tailored software and consultancy
                                  services that drive tangible results.
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                            >
                              How can our organization benefit from your
                              consultancy services?
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Our consultancy services are designed to
                                    empower your organization with strategic
                                    insights, technological expertise, and
                                    customized solutions.
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Whether you're looking to optimize existing
                                    processes, implement new technologies, or
                                    navigate digital transformation, our
                                    consultancy team collaborates with you to
                                    achieve your business objectives efficiently
                                    and effectively.
                                  </span>
                                </li>

                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Offers complete insights through dashboards
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                            >
                              How can we stay informed about your company's
                              updates and industry insights?
                            </a>
                          </div>
                          <div
                            id="collapseTwo"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Stay connected with us through our
                                    newsletter, social media channels, and blog.
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    We regularly share updates on our latest
                                    projects, industry insights, and thought
                                    leadership articles.
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Join our community to receive valuable
                                    information, participate in discussions, and
                                    stay informed about the evolving landscape
                                    of technology and business.
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                            >
                              Well-Received by Our Clients
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Ideal for small and medium-sized enterprises
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Solutions that are affordable for small and
                                    medium-sized businesses
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Happy clients around the globe
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rs-services home-style2 pt-50 pb-50 md-pt-50 md-pb-50 gray-bg">
            <div className="container">
              <div className="sec-title2 text-center md-left mb-40">
                <h2 className="title pb-20">
                  Our services assist you in growing <br />
                  your company.
                </h2>
                <p>
                  We bring a wealth of expertise to the table, with a team
                  skilled in cutting-edge technologies <br />
                  and a proven track record of successful projects.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6 md-mb-50 pr-30 md-pr-l5">
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/de.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="data-engineering">Data engineering</Link>
                      </h3>
                      <p className="services-txt">
                        Data Engineers at SKSoft Systems manage and transform
                        raw data to that of high value. They assist in helping
                        you build strong and effective cloud platforms.
                      </p>
                    </div>
                  </div>
                  {/* <div className="services-item mb-45">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/aw.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="home">Award winning</Link></h3>
                                            <p className="services-txt">SKSoft Systems is an award-winning company that excels in customer satisfaction. We prioritize our customers and that is what makes us unique.</p>
                                        </div>
                                    </div>                                 */}
                  <div className="services-item">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/snowflake.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="snowflake-administration">
                          Enterprise flexibility
                        </Link>
                      </h3>
                      <p className="services-txt">
                        Invest in high availability, frequent backups, and
                        several data storage sites to be ready for any
                        imaginable disaster.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pl-40 md-pl-15">
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/ds.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="data-science">
                          Confident about the future
                        </Link>
                      </h3>
                      <p className="services-txt">
                        {" "}
                        Your company will be prepared for the future if you have
                        a mobile-first, telework architecture that guarantees
                        operational continuity and business resilience.
                      </p>
                    </div>
                  </div>
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style3/2.png"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="data-strategy">Continual time</Link>
                      </h3>
                      <p className="services-txt">
                        {" "}
                        To examine the data that relates to your business, you
                        can do so using any device, from any location, at any
                        time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 md-mb-50">
                  <div class="images-part">
                    <img src={Img3} alt="Images" className="w-100" />
                  </div>
                </div>
                <div class="col-lg-6 pl-46 md-pl-15">
                  <div class="sec-title3">
                    <h2 class="title pb-10">
                      <i>Unleashing Efficiency</i> <br />
                      Explore SKSoft Systems Cloud ERP in Action
                    </h2>
                    <p class="margin-0 pb-10 pt-15">
                      A demonstration of the Cloud ERP Schedule Personalised
                      SKSoft Systems Cloud ERP Overview product, featuring
                      real-time observations accessible at any time and from any
                      location. You may effectively manage your company,
                      evaluate customer interactions, and get proactive business
                      insights at any time, from any place, on any device, with
                      the aid of SKSoft Systems' cloud ERP application suite.
                    </p>

                    <ul class="icon-item pt-15">
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          ERP on the cloud from SKSoft Systems
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          I observed it working for ten minutes.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">One-Hour Tour</span>
                      </li>
                    </ul>
                    <div class="btn-part mt-42">
                      <Link
                        to="/contact-us"
                        class="readon consultant paste-btn more"
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* About Section Start */}
          <div className="rs-about gray-bg2 style1 pt-30 pb-30 md-pt-30 md-pb-30">
            <div className="container">
              <div className="row y-middle">
                <div className="col-lg-12 pr-70 md-pr-15 md-mb-50">
                  <div className="sec-title2 mb-30">
                    <div className="sub-text">Who We Are</div>
                    <h2 className="title mb-23">
                      At SKSoft Systems, we stand out as your best option for
                      several reasons:
                    </h2>
                    <p className="desc mb-0">
                      At SKSoft Systems, our strength lies in our extensive
                      expertise. Our team is proficient in the latest
                      cutting-edge technologies, backed by a proven track record
                      of successfully executed projects. When you choose us,
                      you're tapping into a wealth of knowledge and skill that
                      ensures your project's success.
                    </p>
                  </div>
                  {/* Skillbar Section Start */}
                  <div className="rs-skillbar style1">
                    <ul class="icon-item">
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          We bring a wealth of expertise to the table, with a
                          team skilled in cutting-edge technologies and a proven
                          track record of successful projects.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Our commitment to understanding your unique needs
                          allows us to tailor solutions that precisely fit your
                          business requirements.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          We believe in collaboration. Working closely with you,
                          we ensure transparency, feedback, and a smooth process
                          from concept to delivery.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Our agile methodologies prioritize flexibility,
                          ensuring we deliver high-quality solutions on time,
                          every time.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          From finance to healthcare, we've excelled in various
                          industries. Our adaptable solutions cater to the
                          distinct challenges of each sector.
                        </span>
                      </li>
                    </ul>
                    <div className="col-lg-12 mt-45">
                      <div className="btn-part">
                        <Link
                          className="readon consultant discover"
                          to="/contact-us"
                        >
                          Discover More
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Skillbar Section End */}
                </div>
                {/* <div className="col-lg-4">
                                    <div className="about-img">
                                        <img src={Img38} alt="images" style={{width: '80%'}}/>                                      
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          {/* About Section Start */}

          <div class="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
            <div class="container">
              <div class="row">
                <h1 class="pb-10 hidden-lg hidden-md hidden-sm visible-xs text-center">
                  Suchita Memorial Scholarship
                </h1>
                {/* <div class="col-lg-5 md-mb-50">
                  <div class="images-part">
                    <Link to="/contact-us">
                      <img src={Img42} alt="Images" className="w-100" />
                    </Link>
                  </div>
                </div> */}
                <div class="col-lg-12 pl-46 md-pl-15">
                  <div class="sec-title3">
                    <h1 class="pb-10 hidden-xs">Crafting Digital Success</h1>
                    <p className="mt-40">
                      At SKSoft Systems, we don't just develop software;{" "}
                      <b className="text-danger">
                        e; we architect digital success stories. Our team, armed
                        with a rich tapestry of expertise, dives into the latest
                        technologies to bring you cutting-edge solutions.
                      </b>{" "}
                      From envisioning and strategizing to implementation and
                      beyond, we ensure your journey with us is marked by
                      innovation and efficiency.
                    </p>
                    <p class="margin-0 pb-10  mt-15">
                      From navigating the intricacies of finance to
                      revolutionizing healthcare, SKSoft Systems boasts a
                      versatile portfolio. Our adaptive solutions are engineered
                      to tackle the unique challenges of diverse industries.
                    </p>

                    <p class="margin-0 pb-10  mt-15">
                      When you partner with us, you tap into a reservoir of
                      experience that transcends sectors, ensuring your success
                      in any domain.
                    </p>
                    <ul class="icon-item mt-15">
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Collaboration for Brilliance{" "}
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Agile Excellence, On Time, Every Time
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Versatility Across Industries
                        </span>
                      </li>
                    </ul>
                    <div class="btn-part mt-42">
                      <Link
                        to="/Overview"
                        class="readon consultant paste-btn more"
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
