import React from "react";

export default function AWS() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <div className="rs-breadcrumbs black-bg3 aws-banner">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">
              Amazon Web Services
              <span className="watermark">snowflake</span>
            </h1>
            <span className="sub-text">
              Data retention and buying space on the cloud can be highly
              expensive. But the need for buying cloud space for businesses
              can’t be undermined as well. This is where SKSoft Systems can help
              your business.
            </span>
          </div>
        </div>
      </div>
      {/* Breadcrumbs End */}

      <div className="rs-about pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container custom">
          <div className="row">
            <div className="col-lg-12 pl-60 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-22">
                  Choose SKSoft Systems for better assistance with AWS. Contact
                  us now and say goodbye to your worries!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Here’s how we can help</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Migration <br />
                      Guide
                    </a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    With the advancement in technologies, companies are now
                    shifting from traditional practices to modern ones. We help
                    you migrate from Hadoop to AWS by laying down a suitable and
                    analytical migration guide. Throughout the years, we have
                    designed tools that make the migration process easy, fast,
                    and cost-efficient.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Trusted Data <br /> Platform
                    </a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    We can help you build a better data platform online. With
                    our free cloud automation tool, you can build infrastructure
                    and eliminate errors on the go. We assist in building
                    highly-responsive data platforms so all your data stays in
                    one place.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                {/* <div class="services-icon">
                                <img src="assets/images/services/style3/3.png" alt="Services">
                            </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Data Pipelines with A <br /> WS Cloud Formation
                    </a>
                  </h2>
                  <p className="services-txt">
                    Data pipelines aren’t easy to manage and the complexity
                    increases with heavy data. To make things simple, the AWS
                    cloud formation tool creates your cloud services in a more
                    flexible fashion. The tool manages all your physical IDs and
                    credentials for easy access.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <div className="services-item">
                {/* <div class="services-icon">
                                <img src="assets/images/services/style3/1.png" alt="Services">
                            </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Low-Cost AWS <br /> Cloud DataOps
                    </a>
                  </h2>
                  <p className="services-txt">
                    Utilising the data storage services on AWS cloud can get
                    expensive. Our company can help you save the expense on
                    platform administration costs.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Data Pipelines with AWS Cloud Formation</h2>
            <p className="mt-5">
              AWS Data Pipeline is a web service that helps you reliably process
              and move data between different AWS compute and storage services,
              as well as on-premises data sources, at specified intervals. ...
              AWS Data Pipeline also allows you to move and process data that
              was previously locked up in on-premises data silos.
            </p>
          </div>
          <img
            src="assets/images/pages/aws/1.png"
            className="md-pt-70 md-pb-70"
            alt=""
          />
        </div>
      </div>

      {/* Partner Section Start */}
      <div className="rs-patter-section black-bg pt-80 pb-75">
        <div className="container custom">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items={5}
            data-margin={30}
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout={5000}
            data-smart-speed={800}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-md-device={5}
            data-md-device-nav="false"
            data-md-device-dots="false"
            data-center-mode="false"
            data-ipad-device2={4}
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-ipad-device={4}
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-mobile-device={2}
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
          >
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Partner Section End */}
    </div>
  );
}
