import React from "react";

export default function DataStrategy() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  The significance of data strategy
                </h2>
                <p className="margin-0 pb-40">
                  Almost all businesses gather data in various formats, and a
                  data strategy helps an organisation to organise, process, and
                  analyse this data. Additionally, it places a company in a
                  great position to address problems like:
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Insufficient knowledge of the intricate workings of the
                      business's vital components, such as the supply chain,
                      customers, and competitive environment.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data analysis skills are undermined by concerns related to
                      data privacy, data integrity, and data quality.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      A lack of clarity on the objectives and present company
                      demands (a problem that descriptive analytics might assist
                      in solving).
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Ineffective data transfer between various corporate
                      departments or redundant data entry. Ineffective and
                      sluggish business procedures
                    </span>
                  </li>
                </ul>
                <div className="btn-part mt-42">
                  <a
                    className="readon consultant paste-btn more"
                    href="https://www.udemy.com/course/the-data-strategy-course-building-a-data-driven-business/"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-strategy/1.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Four elements comprise the data strategy.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/4.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Company plan</a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    Your overall business strategy—which refers to the methods
                    you employ to run and enhance your company—should be
                    supported and advanced by your data strategy.
                  </p>
                  <p className="services-txt">
                    {" "}
                    In order to do this, make sure that your data strategy has
                    measurable goals and objectives that align with your overall
                    business plan. One objective of your data strategy would be,
                    for instance, to maintain data storage expenses below a
                    particular level. In order to do this, the plan may list
                    storage products or services that satisfy your budgetary
                    constraints in addition to recommended practices that assist
                    consumers in minimising storage expenses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50 mt-30">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Organizational roles</a>
                  </h2>
                  <p className="services-txt">
                    In order to promote cooperation and prevent duplication, a
                    data strategy should pay attention to organisational roles
                    by recording who does what with the data.
                  </p>
                  <ul className="icon-item mt-20">
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Data engineers, who oversee the data pipeline and are
                        responsible for building an efficient, reliable data
                        architecture
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Those in charge of data engineering, who supervise the
                        data pipeline and are accountable for creating a
                        dependable, effective data architecture
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Professionals with expertise in data analysis and
                        interpretation are known as data analysts.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Business managers, who assist in overseeing data
                        operations and reviewing data reports.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50 ">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/3.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Information architecture</a>
                  </h2>
                  <p className="services-txt">
                    Finding the datasets that are shared by business divisions
                    inside the organisation is the first stage in developing
                    your data architecture. Data catalogues serve as helpful
                    resources in this regard. Review data sources with your team
                    and the people who interact with the data if you don't have
                    a data catalogue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/1.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Data management</a>
                  </h2>
                  <p className="services-txt">
                    All team members are urged by data management to view data
                    as an asset to the company rather than as a result of
                    business operations. It motivates all employees in your
                    company to handle data in accordance with policies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Companies Create a Data Strategy to:</h2>
            <ul className="icon-item mt-30">
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Handle massive data sets that are essential to an
                  organization's success.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Consider how to effectively manage trends and the future.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Promote creativity and create a culture of data.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Encourage rethinking how decisions are made within an
                  organisation.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
