import React from "react";
import Img25 from "../../../assets/images/25.svg";
import Img26 from "../../../assets/images/26.svg";

export default function Payroll() {
  return (
    <>
      <div>
        <div
          id="rs-about"
          className="rs-about main-home pt-20 pb-20 md-pt-20 md-pb-20"
        >
          <div className="container">
            <div className="row y-middle mt-30">
              <div className="col-lg-6 pl-46 md-pl-15">
                <div className="sec-title3">
                  <h2 className="title pb-20">
                    IT Industry leading payroll from SKSoft Systems
                  </h2>
                  <p className="mt-0">
                    You can find the right payroll services tailored to match
                    the needs any kind of business and whatever the number of
                    employees.
                  </p>
                  <ul className="icon-item">
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Collect payroll inputs</span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Define pay components</span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Set pay policy</span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Gross and net salary calculation
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Distribute payslips</span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Deposit and report dues like TDS, PF, ESI etc...
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 md-mb-30 mt-30">
                <div className="images-part">
                  <img src={Img25} alt="Images" className="w-100 " />
                </div>
              </div>
            </div>
            <div className="row y-middle mt-0">
              <div className="col-lg-6 md-mb-30 mt-30">
                <div className="images-part">
                  <img src={Img26} alt="Images" className="w-100 " />
                </div>
              </div>
              <div className="col-lg-6 pl-46 md-pl-15">
                <div className="sec-title3">
                  <h2 className="title pb-20">What services we offer?</h2>
                  <ul className="icon-item">
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Amount required to pay employees during a week, month,
                        or other period.{" "}
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Process of calculating worker's salaries, tax deductions
                        & paying them on-time.{" "}
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Set pay policy</span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Company department that deals with employees, pay and
                        etc...
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">Distribute payslips</span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        List of employees plus information about them.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style2 rs-services-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container custom">
          <h2 className="title pb-20">
            Company Payroll expertise to power your businesses
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Simpler payroll</a>
                  </h3>
                  <div className="desc">
                    SKSoft Systems payroll helps you overcome the many
                    complexities and company risks associated with sourcing,
                    managing and delivering payroll services.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Security services</a>
                  </h3>
                  <div className="desc">
                    Make sure the safety of your company data with the
                    award-winning data security and privacy standards provided
                    by us.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Increased accuracy</a>
                  </h3>
                  <div className="desc">
                    Standardise company payroll process and achieve a best in a
                    model with the help of our payroll.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Decision making</a>
                  </h3>
                  <div className="desc">
                    A single view of company workforce data for reporting,
                    analytics to make insightful decisions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
