import React from "react";

export default function DataEngineering() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  These platforms are usually used in four different ways
                </h2>
                {/* <p className="margin-0 pb-40">AI enables human capabilities of understanding, planning, reasoning, communication and perception to be undertaken by software increasingly effectively, efficiently and at low cost.</p> */}
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data ingestion and storage of large amounts of data
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Algorithm creation by data scientists
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Automation of the data scientist’s machine learning models
                      and algorithms
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data visualisation for employees and clients.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Traditional solution architects for systems that involve
                      SQL databases, web servers, SAP installations and other
                      “standard” 12 systems..
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 md-mb-30 mt-30">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-engineering/1.png"
                  alt="Images"
                  className="w-70 "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-50 pb-100 md-pt-30 md-pb-70">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Who Companies Need</h2>
            <p className="services-txt mt-30">
              For a good company it is absolutely important to get well trained
              data engineers and data scientists.
            </p>
            <p className="services-txt mt-30">
              Think of the data scientist as the professional race car driver. A
              fit athlete with talent and driving skills like you have never
              seen.
            </p>
            <p className="services-txt mt-30">
              It is a common ground between both that drives innovation. Spark
              gives data scientists the tools to do analytics and helps
              engineers to bring the data scientist’s algorithms into
              production. After all, those two decide how good the data platform
              is, how good the analytics insight is and how fast the whole
              system gets into a production ready state.
            </p>
            <div className="mt-30 mb-10">
              <iframe
                width="70%"
                height="500"
                src="https://www.youtube.com/embed/SpaFPPByOhM"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Embark on a journey of innovation, collaboration, and success with
              SKSoft Systems. Let's shape the future of your business together.
            </h2>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}
