import styled from "styled-components";

export const CareersWrapper = styled.div`
  font-size: 16px;

  .rs-process {
    background-color: #f9f9f9;
    padding: 30px 0;
  }

  .sec-title {
    margin-bottom: 30px;
    text-align: center;
  }

  section {
    margin-top: 20px;
    margin-bottom: 40px;

    h4 {
      margin-bottom: 30px;
    }
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    color: #333;
  }

  .number-part {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }

  .number-part:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  }

  .number-title {
    margin-bottom: 20px;
  }

  .number-txt {
    color: #666;
  }

  .loac-text {
    margin-bottom: 10px;
  }

  .btn-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .readon {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    color: #007bff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: white;
    background: #47a5ff;
  }

  .readon:hover {
    background-color: #007bff;
    color: #fff;
  }

  /* Style for unordered list */
  ul {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    list-style: inherit;
    padding-left: 30px;
  }

  /* Style for list items */
  li {
    margin-bottom: 10px;
    color: #444;
    font-size: 16px;
    line-height: 1.6;
  }

  strong,
  h4 {
    font-weight: bold;
    color: black;
  }

  .success-wrapper {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
      color: #4caf50;
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #333;
    }
  }
`;

export const SuccessWrapper = styled.div`
  background-color: rgb(251 251 251);
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  h2 {
    color: #4caf50;
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  i {
    color: green;
  }

  p {
    font-size: 26px;
    margin-top: 20px;
  }
`;

export const FormWrapper = styled.section`
  border: solid 1px #ccc;
  padding: 50px;
  background-color: #51519a;
  border-radius: 12px;
  height: 100%;
  padding-bottom: 50px;
  width: 50%;
  color: white;

  @media (max-width: 480px) {
    width: 100%;
  }

  h2 {
    padding-bottom: 30px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    input,
    select {
      border-radius: 6px;
      width: 100%;
      min-height: 40px;
      border: solid 1px #eee;
      padding-left: 8px;
      margin-top: 4px;
      color: black;
    }
  }

  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 20px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
