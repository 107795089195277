import React from "react";
import Img15 from "../../../assets/images/15.png";
import Img16 from "../../../assets/images/16.png";
import Img17 from "../../../assets/images/17.png";
import Img18 from "../../../assets/images/18.png";
import Img19 from "../../../assets/images/19.png";
import Img20 from "../../../assets/images/20.png";
import Img21 from "../../../assets/images/21.png";

export default function IndustryExpertise() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-20">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  Comprehensive Support for Every Industry?
                </h2>
                <p className="mt-10">
                  SKSoft Systems transforms businesses from various industries
                  by harnessing the immense power of Big Data. Our solutions
                  cater to the dynamic needs of sectors such as finance,
                  healthcare, telecommunications, and more. Experience the
                  flexibility and adaptability required to navigate the
                  complexities of your industry successfully.
                </p>
                <p className="mt-10">
                  We understand that challenges can arise at any moment. That's
                  why SKSoft Systems provides around-the-clock customer support
                  to ensure you receive assistance whenever you need it. Our
                  dedicated team is ready to help you overcome hurdles and
                  maximize the benefits of our Big Data solutions.
                </p>

                {/* <div className="images-part">
                  <img src={Img11} alt="Images" className="w-100 " />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row y-middle mt-20">
          <div
            className="d-flex justify-content-around mt-30 box-content"
            style={{ backgroundImage: `url(${Img21})`, padding: 100 }}
          >
            <div className="sec-title3 d-flex justify-content-center flex-column">
              <h2 className="title pb-10 text-center">
                {" "}
                So, why do you hesitate?{" "}
              </h2>
              <h2 className="title pb-20">
                Start using SKSoft Systems right now to experience the impact it
                can have on your company!
              </h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-6 border-right" style={{ paddingRight: 50 }}>
              <section className="mb-30">
                <h2 className="title mb-30">
                  Strong Arguments for Using SKSoft Systems Cloud CRM
                </h2>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        The Best Customer Management Solution
                      </h2>
                      <p className="mt-10">
                        You can effortlessly manage your sales pipeline and
                        customer data in one location with SKSoft Systems Cloud
                        CRM. Your sales and marketing procedures are automated
                        by our robust software, allowing you to concentrate on
                        expanding your company.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        Sales: Boost your revenue and complete more
                        transactions.
                      </h2>
                      <p className="mt-10">
                        You can close more deals and boost your sales with the
                        help of SKSoft Systems Cloud CRM. In order to help you
                        complete more business more quickly, our software
                        automates your sales process and gives you comprehensive
                        insights into your pipeline.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">Marketing </h2>
                      <p className="mt-10">
                        Expand your consumer base and strengthen your brand
                        Expand your customer base and build your brand with ease
                        using SKSoft Systems Cloud CRM. With our effective
                        marketing tools, you can
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-sm-6" style={{ paddingLeft: 50 }}>
              <section className="mb-30">
                <h2 className="title mb-30">
                  CRM Solutions for Client Support from SKSoft Systems
                </h2>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        The modern marketing strategy is customer service.
                      </h2>
                      <p className="mt-10">
                        Customers are more connected than ever because to social
                        media. It is crucial to have a good customer service
                        staff in place since customers may and will share their
                        positive (and negative) experiences with your business
                        online.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        You can monitor your customer interactions using our CRM
                        solution.
                      </h2>
                      <p className="mt-10">
                        You can manage all of your client interactions centrally
                        with our CRM (customer relationship management)
                        solution. This covers conversations over the phone,
                        emails, live chats, and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        You can quickly resolve client complaints using our CRM
                        system.
                      </h2>
                      <p className="mt-10">
                        You can handle client issues more rapidly if you use our
                        CRM solution. This is because you'll be equipped with
                        all the necessary knowledge.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section Start */}
      <div className="rs-services style2 rs-services-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container custom">
          <h2 className="title pb-20">We expertise in</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img15} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Sales and Marketing</a>
                  </h3>
                  <div className="desc">
                    Keep track of your leads, customers, and sales pipeline all
                    in one place
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img16} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Customer Service</a>
                  </h3>
                  <div className="desc">
                    Improve customer satisfaction with streamlined case
                    management.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img17} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Human Resources</a>
                  </h3>
                  <div className="desc">
                    From onboarding to performance reviews, manage your employee
                    data securely
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img18} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Finance</a>
                  </h3>
                  <div className="desc">
                    Get a real-time view of your financial data to make informed
                    decisions
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img19} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Information Technology</a>
                  </h3>
                  <div className="desc">
                    Helpdesk, inventory and project management made easy
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img20} alt="" />
                </div>
                <div className="content-part ">
                  <h3 className="title mt-10">
                    <a href="/">Manufacturing</a>
                  </h3>
                  <div className="desc">
                    Streamline your production process and keep track of your
                    inventory
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}
