import React from "react";

export default function CloudFoundation() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/pages/cloud-foundation/1.png"
                  alt="Images"
                  className="w-100 "
                />
              </div>
            </div>
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  How do you create a cloud infrastructure?
                </h2>
                {/* <p className="margin-0 pb-40">AI enables human capabilities of understanding, planning, reasoning, communication and perception to be undertaken by software increasingly effectively, efficiently and at low cost.</p> */}
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Think Adaptive and Elastic.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Treat servers as disposable resources.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Automate Automate Automate.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Implement loose coupling.</span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Focus on services, not servers.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Database is the base of it all.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Be sure to remove single points of failure.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Optimize for cost.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-50 pb-100 md-pt-30 md-pb-70">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Cloud Infrastructure Vs. Cloud Architecture
            </h2>
            <p className="services-txt mt-30">
              he way that these different components (hardware, virtualization,
              storage, and network) are configured is called cloud architecture.
              Cloud infrastructure includes the tools themselves, used to design
              and build that architecture. Infrastructure is what allows a
              company to continue to grow, scale-up, and develop without
              constantly expanding the physical requirements of the business.
            </p>
            <div className="mt-30 mb-30">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/173FCWet5Tk"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Components that make up Cloud Infrastructure?
            </h2>
            <p className="service-txt">
              The main components that make up cloud infrastructure break down
              into four key groups:
            </p>
            {/* <img src="assets/images/pages/ai/2.png" alt="Services" className='mt-30' /> */}
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Hardware</a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    Composed of physical equipment which can be stored at any
                    location, hardware includes the equipment necessary to link
                    machines to a single cloud.{" "}
                  </p>
                  <p className="services-txt">
                    {" "}
                    Hardware components include servers, power supplies, memory
                    and storage, processing units, and more — all of which are
                    responsible for the performance, security, and availability
                    of services and information for both a business and an
                    individual user.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Virtualization</a>
                  </h2>
                  <p className="services-txt">
                    The foundation of cloud computing, virtualization is what
                    connects all the hardware together and ensures that devices
                    can work without a physical connection to the hardware. It
                    separates the service itself from its physical location or
                    PC, allowing users to access services and applications
                    remotely. One of the key benefits presented by the use of
                    virtualization is the scale of growth that an organization
                    or cloud network can employ, based on need and consumption.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Network</a>
                  </h2>
                  <p className="services-txt">
                    The network starts with a physical presence and then adds a
                    layer of virtual support and communication on top to permit
                    cloud resources to be shared by users who are listed as part
                    of the same network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}

      {/* Partner Section Start */}
      <div className="rs-patter-section black-bg pt-80 pb-75">
        <div className="container custom">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items={5}
            data-margin={30}
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout={5000}
            data-smart-speed={800}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-md-device={5}
            data-md-device-nav="false"
            data-md-device-dots="false"
            data-center-mode="false"
            data-ipad-device2={4}
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-ipad-device={4}
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-mobile-device={2}
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
          >
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Partner Section End */}
    </div>
  );
}
