import React from "react";
import C1 from "../../assets/images/c1.svg";

const scalabilitydata = [
  {
    title: "Occupational relationships",
    content:
      "It is simple to integrate with the collaborative tools of your choice and was created for telework and mobile situations",
  },
  {
    title: "Continual time",
    content:
      "Open APIs make it possible to link commercial apps, video conferencing systems, and collaborative tools fast",
  },
  {
    title: "Collaborating online",
    content:
      "To examine the data that relates to your business, you can do so using any device, from any location, at any time.",
  },
  {
    title: "Confident about the future",
    content:
      "Your company will be prepared for the future if you have a mobile-first, telework architecture that guarantees operational continuity and business resilience.",
  },
  {
    title: "Enterprise flexibility",
    content:
      "Invest in high availability, frequent backups, and several data storage sites to be ready for any imaginable disaster.",
  },
];

export default function ScheduleDemo() {
  const Img41 = require("../../assets/images/41.mp4");

  return (
    <>
      <div>
        <div className="rs-process style2 pt-20 pb-30 md-pt-20 md-pb-50">
          <div className="container custom">
            <div className="row y-middle mb-30">
              <div className="col-lg-12">
                <div className="sec-title md-mb-30">
                  <h2 className="title">
                    Delivering Excellent Results Across Industries
                  </h2>
                  <p className="mt-10">
                    SKSoft Systems is equipped to help businesses from different
                    industries go sky-high! Our SaaS-based ERP solution is
                    helping organizations meet extraordinary goals
                  </p>
                  <p>
                    In addition, SKSoft Systems is a comprehensive ERP suite
                    packed with features to help businesses from different
                    industries go sky-high. With 24*7 customer support, SKSoft
                    Systems is always there to help you overcome any challenges
                    you may face.
                  </p>
                  <p>
                    SKSoft Systems has been the fastest-growing Cloud ERP
                    company for seven years in a row because we put our
                    customers first.
                  </p>
                </div>
              </div>
            </div>
            <p>
              <img src={C1} className="w-100" alt="" />
            </p>
          </div>
        </div>

        {/* Process Section Start */}

        <div className="rs-process style2 gray-bg2 pt-50 pb-30 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="sec-title md-mb-30">
                <h2 className="title">Are you looking for a demo?</h2>
                <p className="mt-10 mb-30">
                  SKSoft Systems is a complete package for your marketing needs,
                  with everything you need to start quickly and easily. Our ERP
                  is connected or contracted with EVALON and uses its CENPOS for
                  financial transactions. With its intuitive interface and
                  easy-to-use features, SKSoft Systems is the perfect CRM
                  solution for small businesses and startups. Our ERP is capable
                  of transactions in Cash, checks, credit cards, and virtual
                  currencies.
                </p>
              </div>

              <div className="row sec-title">
                <h2 className="title pb-10">English Language</h2>
                <div className="col-md-6">
                  <div className="box">
                    <h3 className="">Bill Generation demo</h3>
                    <video width="100%" height="400" controls>
                      <source src={Img41} type="video/mp4" />
                    </video>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="box">
                    <h3 className="">Bill Generation demo</h3>
                    <video width="100%" height="400" controls>
                      <source src={Img41} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>

              <div className="row sec-title mt-50">
                <h2 className="title pb-10">English Language</h2>
                <div className="col-md-6">
                  <div className="box">
                    <h3 className="">Bill Generation demo</h3>
                    <video width="100%" height="400" controls>
                      <source src={Img41} type="video/mp4" />
                    </video>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="box">
                    <h3 className="">Bill Generation demo</h3>
                    <video width="100%" height="400" controls>
                      <source src={Img41} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="rs-process style2 pt-50 pb-30 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="sec-title md-mb-30">
                <h2 className="title pb-50">Here, we're adding few application screens. Please check it out.</h2>              
              </div>

              <div className="col-md-6">
                <div className="rs-videos faq">
                  <div className="demo-video"></div>
                  <div className="animate-border">
                    <a href="/" className="popup-border" href={Img7}>
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="rs-videos faq">
                  <div className="punjabi-demo-video"></div>
                  <div className="animate-border">
                    <a href="/" className="popup-border" href={Img8}>
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div> */}
        {/* Process Section End */}

        {/* Process Section Start */}
        <div className="rs-process style2 pt-20 pb-30 md-pt-20 md-pb-50">
          <div className="container custom">
            <div className="row y-middle mt-0 mb-30">
              <div className="col-lg-5">
                <div className="sec-title md-mb-30">
                  <h2 className="title">A well-known stage</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {scalabilitydata.map((item: any) => {
                return (
                  <div className="col-lg-4 col-md-6 mb-30">
                    <div className="rs-addon-number">
                      <div className="number-part">
                        <div className="number-title">
                          <h3 className="title">{item.title}</h3>
                        </div>
                        <div className="number-txt">{item.content}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* Process Section End */}
      </div>
    </>
  );
}
