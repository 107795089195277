import React, { useState } from "react";
import { SuccessWrapper } from "./styles";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Success() {
  return (
    <div className="container mt-5 mb-5 d-flex justify-content-center">
      <SuccessWrapper>
        <section className="success-wrapper">
          <i className="fa fa-check-circle fa-5x"></i>
          <h2>The form has been successfully submitted. Thank you!</h2>
          <p>We'll get back to you very soon.</p>
        </section>
      </SuccessWrapper>
    </div>
  );
}
