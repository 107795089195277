import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CareersWrapper } from "./styles";

export default function Careers() {
  const [expandedJob, setExpandedJob] = useState(null);

  const toggleExpand = (jobId: any) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };

  const careersData = [
    {
      id: 1,
      designation: "Full Stack Developer",
      job_id: "S12532",
      job_postedon: "04-02-2024",
      description:
        "We are looking to add a skilled Full Stack Developer to our vibrant team. You will be in charge of creating and managing front-end and back-end apps as a Full Stack Developer, making sure that they integrate seamlessly. You'll collaborate closely with our product and design teams to provide excellent, user-friendly software solutions.",
      responsibilities: [
        "Developing and maintaining web applications utilising contemporary frameworks and technologies is your responsibility.",
        "Create, execute, and verify functionalities over the whole web development stack.",
        "Collaborate with cross-functional teams to define, develop, and implement new features.",
        "Applications should be optimised for optimum performance and scalability.",
        "Debugg and troubleshoot problems to guarantee that apps run smoothly.",
      ],
      requiredSkills: [
        "Proficiency with front-end technologies, including HTML5, CSS3, JavaScript, and contemporary JavaScript frameworks (such React, Angular, and Vue.js), is necessary.",
        "solid experience developing back-end applications in Python, Java, and Node.js.",
        "familiarity with both relational and non-relational databases, such as MongoDB and MySQL.",
        "familiarity with server-side CSS preprocessors (such as Sass and Less).",
        "knowledge of version control systems like Git.",
        "comprehension of recommended practices for online security.",
        "Good communication and problem-solving abilities.",
        "Capacity to operate both individually and together in a hectic setting.",
      ],
    },
    {
      id: 2,
      designation: ".NET Developer",
      job_id: "S12348",
      job_postedon: "25-02-2024",
      description:
        "We are looking to fill our team with a talented.NET Developer. Using the.NET framework, you will be in charge of creating and managing high-caliber software solutions as a.NET developer. Together with our development team, you will collaborate closely to produce scalable, reliable apps that satisfy our clients' demands.",
      responsibilities: [
        "Create, create, and manage online and desktop applications utilising the.NET framework.",
        "Collaborate with cross-functional teams to develop, create, and deploy new features.",
        "Write clean, effective, and well- proved law that follows stylish practices.",
        "Test and debug apps to ensure they run smoothly and reliably.",
        "Participate in code reviews to verify that the code is high quality and consistent.",
        "Stay current on the newest technology and market trends in.NET development.",
      ],
      requiredSkills: [
        "Required skills include proficiency in C# and the.NET framework.",
        "Experience with ASP.NET MVC and/or ASP.NET Core.",
        "A solid comprehension of object-oriented programming (OOP) principles.",
        "Front-end technology knowledge, including HTML5, CSS3, JavaScript, and jQuery.",
        "Proficiency in SQL Server or similar relational database systems.",
        "Understanding the software development lifecycle (SDLC) techniques.",
        "Excellent problem-solving and analytical abilities.",
        "Ability to operate both alone and collaboratively in a team setting.",
      ],
    },
    {
      id: 3,
      designation: "Java Developer",
      job_id: "S12348",
      job_postedon: "25-02-2024",
      description:
        "We are seeking a skilled Java Developer to join our team. As a Java Developer, you will be responsible for developing and maintaining high-quality software solutions using Java and related technologies. You will collaborate with our development team to design and implement scalable, reliable, and efficient applications.",
      responsibilities: [
        "Design, develop, and maintain Java-based web applications.",
        "Implement new features and enhancements to existing applications.",
        "Work closely with cross-functional teams to understand requirements and deliver solutions.",
        "Write clean, efficient, and well-documented code following best practices.",
        "Test and debug applications to ensure optimal performance and reliability.",
        "Stay up-to-date with the latest technologies and industry trends in Java development.",
      ],
      requiredSkills: [
        "Proficiency in Java programming language.",
        "Experience with Spring Framework (Spring Boot, Spring MVC, Spring Security, etc.).",
        "Strong understanding of object-oriented programming (OOP) principles.",
        "Knowledge of web technologies such as HTML, CSS, and JavaScript.",
        "Experience with relational databases (e.g., MySQL, PostgreSQL).",
        "Familiarity with version control systems such as Git.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 4,
      designation: "SAP Developer",
      job_id: "S13289",
      job_postedon: "06-02-2024",
      description:
        "We are looking for a skilled SAP Developer to join our team. As a SAP Developer, you will be responsible for developing and maintaining SAP solutions to meet business requirements. You will collaborate with our clients and internal teams to design, implement, and support SAP applications and integrations.",
      responsibilities: [
        "Design, develop, and configure SAP solutions based on business requirements.",
        "Customize and enhance existing SAP applications and functionalities.",
        "Collaborate with cross-functional teams to define, design, and implement SAP projects.",
        "Provide technical support and troubleshooting for SAP applications and integrations.",
        "Ensure the integrity and security of SAP systems and data.",
        "Stay up-to-date with the latest SAP technologies and best practices.",
      ],
      requiredSkills: [
        "Strong knowledge of SAP modules such as SAP ERP, SAP S/4HANA, SAP BW/4HANA, etc.",
        "Experience with SAP ABAP programming language.",
        "Understanding of SAP Fiori/UI5 development.",
        "Knowledge of SAP integration technologies (RFC, BAPI, IDoc, PI/PO, etc.).",
        "Ability to analyze and translate business requirements into technical solutions.",
        "Excellent problem-solving and communication skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 5,
      designation: "Sales Force Developer",
      job_id: "S15478",
      job_postedon: "12-02-2024",
      description:
        "We are seeking a talented Sales Force Developer to join our team. As a Sales Force Developer, you will be responsible for designing, developing, and maintaining custom solutions on the Salesforce platform. You will work closely with our clients and internal teams to deliver high-quality Salesforce applications that meet business requirements.",
      responsibilities: [
        "Design, develop, and customize Salesforce solutions to meet business requirements.",
        "Implement and integrate Salesforce applications, modules, and features.",
        "Collaborate with stakeholders to define, design, and deliver Salesforce projects.",
        "Provide technical support and troubleshooting for Salesforce applications and integrations.",
        "Ensure data integrity and security within the Salesforce environment.",
        "Stay up-to-date with Salesforce technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in Salesforce development technologies such as Apex, Visualforce, Lightning Components.",
        "Experience with Salesforce configuration, customization, and integration.",
        "Knowledge of Salesforce CRM functionalities and modules.",
        "Understanding of Salesforce development best practices and design patterns.",
        "Ability to work independently and collaboratively in a team environment.",
        "Excellent problem-solving and communication skills.",
      ],
    },
    {
      id: 6,
      designation: "Data Engineer",
      job_id: "S15245",
      job_postedon: "10-02-2024",
      description:
        "We are looking for a skilled Data Engineer to join our team. As a Data Engineer, you will be responsible for designing, building, and maintaining scalable data pipelines and systems. You will work closely with our data science and analytics teams to ensure efficient data processing and analysis.",
      responsibilities: [
        "Design, build, and maintain data pipelines and ETL processes.",
        "Implement data integration and transformation solutions for structured and unstructured data.",
        "Optimize data pipelines for performance, scalability, and reliability.",
        "Collaborate with data scientists and analysts to support their data needs.",
        "Ensure data quality, consistency, and integrity across various data sources and systems.",
        "Stay up-to-date with emerging technologies and best practices in data engineering.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Python, Java, or Scala.",
        "Experience with data processing frameworks such as Apache Spark, Apache Beam, or Apache Flink.",
        "Knowledge of distributed computing and parallel processing.",
        "Familiarity with cloud platforms and services (e.g., AWS, GCP, Azure).",
        "Understanding of relational and NoSQL databases.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    // Add more job data here...
    {
      id: 7,
      designation: "BI Developer",
      job_id: "S13579",
      job_postedon: "15-02-2024",
      description:
        "We are seeking a skilled BI Developer to join our team. As a BI Developer, you will be responsible for designing, developing, and maintaining business intelligence solutions to support decision-making processes. You will work closely with stakeholders to gather requirements and deliver actionable insights through data visualization and reporting tools.",
      responsibilities: [
        "Design, develop, and maintain ETL processes to extract, transform, and load data from various sources.",
        "Create and optimize data models and schemas to support reporting and analytics requirements.",
        "Develop interactive dashboards and reports using BI tools such as Tableau, Power BI, or QlikView.",
        "Collaborate with business users to understand their data needs and translate requirements into technical solutions.",
        "Perform data analysis to identify trends, patterns, and insights for business stakeholders.",
        "Ensure the accuracy, consistency, and reliability of data by implementing data quality and governance processes.",
      ],
      requiredSkills: [
        "Proficiency in SQL and experience with database technologies such as SQL Server, MySQL, or Oracle.",
        "Experience with ETL tools such as Informatica, Talend, or SSIS.",
        "Familiarity with BI reporting tools such as Tableau, Power BI, or QlikView.",
        "Strong analytical and problem-solving skills.",
        "Ability to communicate effectively with both technical and non-technical stakeholders.",
        "Experience working in an Agile development environment.",
      ],
    },
    {
      id: 8,
      designation: "QA Engineer",
      job_id: "S14035",
      job_postedon: "20-02-2024",
      description:
        "We are looking for a talented QA Engineer to join our team. As a QA Engineer, you will be responsible for designing and implementing test strategies, conducting test cases, and identifying defects to ensure the quality and reliability of our software products. You will work closely with the development team to ensure that the software meets the required standards and specifications.",
      responsibilities: [
        "Develop and execute test plans, test cases, and test scripts to ensure the quality of software products.",
        "Identify, record, document, and track bugs using bug tracking tools such as Jira or Bugzilla.",
        "Collaborate with the development team to identify root causes of issues and implement corrective actions.",
        "Perform manual and automated testing of web and mobile applications.",
        "Create and maintain automated test scripts using testing frameworks such as Selenium or Appium.",
        "Conduct regression testing to ensure that software changes have not adversely affected existing functionality.",
      ],
      requiredSkills: [
        "Solid understanding of software QA methodologies, tools, and processes.",
        "Experience with test automation tools such as Selenium, Appium, or JUnit.",
        "Familiarity with programming languages such as Java, Python, or JavaScript.",
        "Experience with API testing using tools like Postman or SoapUI.",
        "Strong analytical and problem-solving skills.",
        "Excellent communication and collaboration abilities.",
        "Attention to detail and commitment to quality.",
        "Ability to work independently and in a team environment.",
      ],
    },
    {
      id: 9,
      designation: "Software Engineer",
      job_id: "S14578",
      job_postedon: "22-02-2024",
      description:
        "We are seeking a skilled Software Engineer to join our team. As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications to meet customer requirements. You will work closely with cross-functional teams to deliver high-quality software solutions that meet business needs.",
      responsibilities: [
        "Design, develop, and maintain software applications using programming languages such as Java, C++, or Python.",
        "Participate in requirements analysis, design, and code reviews.",
        "Collaborate with product managers and software developers to deliver new features and enhancements.",
        "Conduct unit testing and debugging to ensure software meets specifications.",
        "Contribute to the continuous improvement of software development processes and methodologies.",
        "Stay up-to-date with emerging technologies and industry trends.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Java, C++, or Python.",
        "Experience with software development methodologies such as Agile or Scrum.",
        "Knowledge of software engineering best practices and design patterns.",
        "Strong problem-solving and analytical skills.",
        "Excellent communication and collaboration abilities.",
        "Ability to work independently and in a team environment.",
        "Attention to detail and commitment to quality.",
      ],
    },
    {
      id: 10,
      designation: "Product Manager",
      job_id: "S15098",
      job_postedon: "28-02-2024",
      description:
        "We are looking for an experienced Product Manager to join our team. As a Product Manager, you will be responsible for the overall success of our products, from conception to launch. You will work closely with cross-functional teams to define product strategy, prioritize features, and drive product development efforts.",
      responsibilities: [
        "Define product vision, strategy, and roadmap based on market and customer insights.",
        "Collaborate with stakeholders to gather requirements and prioritize features.",
        "Create detailed product specifications, user stories, and acceptance criteria.",
        "Work closely with design and development teams to ensure timely delivery of high-quality products.",
        "Monitor and analyze product performance metrics to identify opportunities for improvement.",
        "Communicate product updates and releases to internal teams and external stakeholders.",
      ],
      requiredSkills: [
        "Proven experience as a Product Manager or similar role.",
        "Strong understanding of product management principles and practices.",
        "Excellent project management and organizational skills.",
        "Ability to translate customer needs into product requirements.",
        "Experience with Agile development methodologies.",
        "Strong analytical and problem-solving skills.",
        "Excellent communication and interpersonal abilities.",
        "Ability to work effectively in a fast-paced, dynamic environment.",
      ],
    },
    {
      id: 11,
      designation: "RPA Developer",
      job_id: "S16543",
      job_postedon: "02-03-2024",
      description:
        "We are seeking a talented RPA Developer to join our team. As an RPA Developer, you will be responsible for designing, developing, and implementing robotic process automation solutions to automate business processes. You will work closely with stakeholders to understand their requirements and design efficient RPA workflows.",
      responsibilities: [
        "Design, develop, and implement robotic process automation solutions using RPA tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Analyze business processes and identify opportunities for automation.",
        "Collaborate with business analysts and stakeholders to gather requirements and define RPA workflows.",
        "Develop and maintain documentation for RPA processes, including process diagrams, requirements, and test cases.",
        "Test and debug RPA solutions to ensure accuracy and reliability.",
        "Provide training and support to end users on using RPA solutions.",
      ],
      requiredSkills: [
        "Proficiency in RPA tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Experience with programming languages such as C#, Python, or Java.",
        "Strong analytical and problem-solving skills.",
        "Ability to understand complex business processes and automate them efficiently.",
        "Excellent communication and collaboration abilities.",
        "Attention to detail and commitment to quality.",
        "Ability to work independently and in a team environment.",
      ],
    },
    {
      id: 12,
      designation: "Data Analyst",
      job_id: "DA67891",
      job_postedon: "02-03-2024",
      description:
        "We are seeking a talented Data Analyst to join our team. As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting data to inform business decision-making. You will work closely with stakeholders to understand their data needs and provide insights through data analysis and visualization.",
      responsibilities: [
        "Collect, clean, and preprocess data from various sources.",
        "Perform data analysis to identify trends, patterns, and insights.",
        "Prepare reports and visualizations to communicate findings to stakeholders.",
        "Collaborate with cross-functional teams to understand data requirements and deliver solutions.",
        "Implement data quality and governance processes to ensure the accuracy and reliability of data.",
        "Stay up-to-date with emerging technologies and trends in data analysis and visualization.",
      ],
      requiredSkills: [
        "Proficiency in SQL and data analysis tools such as Excel, Python, or R.",
        "Strong analytical and problem-solving skills.",
        "Experience with data visualization tools such as Tableau or Power BI.",
        "Ability to communicate effectively with both technical and non-technical stakeholders.",
        "Experience working in an Agile development environment.",
        "Attention to detail and commitment to quality.",
        "Ability to work independently and in a team environment.",
      ],
    },
  ];

  return (
    <CareersWrapper>
      {/* Process Section Start */}
      <div className="rs-process style2 pt-30 pb-50 md-pt-30 md-pb-50">
        <div className="container custom">
          <div className="row y-middle mb-50">
            <div className="col-lg-12">
              <div className="sec-title mb-0">
                <h2 className="title mb-0">Job Openings at SK SOFT SYSTEMS</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {careersData.map((item) => {
              return (
                <div className="col-lg-12 mb-30" key={item.id}>
                  <div className="rs-addon-number">
                    <div className="number-part">
                      <div className="number-title">
                        <h3 className="title">{item.designation}</h3>
                      </div>
                      <div className="number-txt">
                        <div className="loac-text mb-1 pb-0">
                          <strong>JOB ID:</strong> {item.job_id}
                        </div>
                        <div className="loac-text">
                          <strong>Job Posted On:</strong> {item.job_postedon}
                        </div>
                        <p>{item.description}</p>
                        {expandedJob === item.id && (
                          <div>
                            <section>
                              <h4 className="mt-5">Responsibilities:</h4>
                              <ul>
                                {item.responsibilities.map(
                                  (responsibility, index) => (
                                    <li key={index}>{responsibility}</li>
                                  )
                                )}
                              </ul>
                            </section>
                            <section>
                              <h4>Required Skills:</h4>
                              <ul>
                                {item.requiredSkills.map((skill, index) => (
                                  <li key={index}>{skill}</li>
                                ))}
                              </ul>
                            </section>
                          </div>
                        )}
                      </div>
                      <div className="btn-part">
                        <button
                          className="readon apply"
                          onClick={() => toggleExpand(item.id)}
                        >
                          {expandedJob === item.id
                            ? "Hide Details"
                            : "Job Details"}
                        </button>
                        <Link to="/RegistrationForm" className="readon apply">
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Process Section End */}
    </CareersWrapper>
  );
}
